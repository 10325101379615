.blur {
  background-color: black;
  height: 100%;
  opacity: 0.6;
}

.canvas {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.bg-image {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.sidebar {
  position: fixed;
  height: 100vh;
  overflow: hidden;
  background-color: black;
  width: 33vw;
}

.sidebar-header {
  position: relative;
  display: flex;
  top: 0px;
  left: 0px;
  z-index: 2;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-top: 5vh;
}

.avatar {
  height: 150px;
  width: auto;
}

.avatar > img {
  border-radius: 50%;
  width: 150px;
  height: auto;
}

.quote {
  padding-top: 30px;
  padding-left: 15%;
  padding-right: 15%;
  color: wheat;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.bg {
  display: block;
  z-index: 1;
  opacity: 1;
}

.jumbotron {
  color: white;
  text-shadow: #444 0 1px 1px;
  z-index: 2;
}

.container {
  z-index: -1;
}


